<template>
  <div>
    <!-- 二维码 -->
    <div class="headerImg">
      <img src="./assets/7.png" alt="" class="img">
    </div>
    <!-- 掌柜直采介绍 -->
    <div class="introduceBox">
      <div class="zhanggui">ZHANGGUI</div>
      <div style="font-size: 35px;margin-top: -40px;">掌柜直采介绍</div>
      <div class="introduce">
        <ul class="introduceLeft">
          <li>
            <div class="placeAnOrder">当日下单 明日自提</div>
            <div>用户可在每天0点至23点通过</div>
          </li>
          <li>
            <div class="placeAnOrder">当日下单 明日自提</div>
            <div>用户可在每天0点至23点通过</div>
          </li>
          <li>
            <div class="placeAnOrder">当日下单 明日自提</div>
            <div>用户可在每天0点至23点通过</div>
          </li>
        </ul>
        <div>
          <img src="./assets/shucai.png" alt="" style="width: 652px;">
        </div>
      </div>
    </div>

    <!-- 数字化升级 -->
    <div class="digitizationBox">
      <div class="zhanggui" style="color: #ffe1b4;">UPGRADE</div>
      <div style="font-size: 35px;margin-top: -40px;">数字化升级</div>
      <div style="width: 1040px;text-align: center;margin: 60px auto;line-height: 26px;">
        免费帮商家搭建微信小程序商城，每个商品可生成独立的店铺二维码，客户扫码直接进入您的在线店铺下单订货，线上卖货、在线接单，订单状态实时跟踪，发货、配货不漏单，对单查账一键搞定。一对一服务，为每位商家配备专属的企业顾问，企业顾问可现场为您的店铺完成数字化升级，您只需要准备好店铺营业执照、商品图片、商品价格，企业顾问全程帮您搭建店铺、上传商品、美化商品图片，
        关键是不收费、不收费、不收费！！！
      </div>
      <div>
        <img src="./assets/shouji.png" alt="" style="width: 1040px;">
      </div>
    </div>

    <!-- 合作优势 -->
    <div class="digitizationBox" style="background: #f8f8f8;">
      <div class="zhanggui" style="color: #ffe1b4;">UPGRADE</div>
      <div style="font-size: 35px;margin-top: -40px;">合作优势</div>
      <ul class="advantageBox">
        <li>
          <img src="./assets/12.png" alt="" class="advantageImg">
          <div class="digitization">
            <div class="advantage">
              <div class="title">
                <span class="hhh">企业顾问服务</span>
                <span class="ct"></span>
              </div>
              <div class="content">
                赊销欠账顾客托管、赊销欠帐顾客资信审核、赊销欠款订单实时跟踪、谕期账单催收、赊账合同跟随订单自动生成
              </div>
            </div>
          </div>
        </li>
        <li>
          <img src="./assets/13.png" alt="" class="advantageImg">
          <div class="digitization">
            <div class="advantage">
              <div class="title">
                <span class="hhh">数据保障</span>
                <span class="ct"></span>
              </div>
              <div class="content">
                阿里云端双服务器，15年超长合约，数据自动备份，双保险，地球不爆炸，账单不丟失。
              </div>
            </div>
          </div>
        </li>
        <li>
          <img src="./assets/14.png" alt="" class="advantageImg">
          <div class="digitization">
            <div class="advantage">
              <div class="title">
                <span class="hhh">无硬件要求</span>
                <span class="ct"></span>
              </div>
              <div class="content">
                无需花几千上万元购买平板、电脑、收银机等 硬件设备，手机可直接操作，小票打印机直接送，真正做到为您的企业降本增效。
              </div>
            </div>
          </div>
        </li>
        <li>
          <img src="./assets/15.png" alt="" class="advantageImg">
          <div class="digitization">
            <div class="advantage">
              <div class="title">
                <span class="hhh">超低成本</span>
                <span class="ct"></span>
              </div>
              <div class="content">
                免费试用，前期无任何费用，不满意无需付费，专属企业顾问为您服务到底。（未续费用户免费催收、赊账合同功能无法使用，但试用期内产生的赊账账单会一直保留、免费催收）
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div style="background: #333;">
      <ul class="footer">
      <li>版权信息：Copyright 2023 by 赣州市森佐科技有限公司</li>
      <li style="margin: 0 20px;">公司地址：赣州市章贡区飞天电商园3栋4楼</li>
      <li>
        <a href="https://beian.miit.gov.cn">备案号：赣ICP备2022010572号-1</a>
      </li>
    </ul>
    </div>

  </div>
</template>

<script>


export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

img {
  vertical-align: top;
}

.headerImg {
  display: flex;
  justify-content: center;
}

.img {
  width: 100%;
}

.introduceBox {
  width: 100%;
  /* height: 846px; */
  background: #FF9A02;
  padding: 1px;
  text-align: center;
  box-sizing: border-box;
}

.zhanggui {
  font-size: 60px;
  color: #ffb84d;
  font-weight: bold;
  margin-top: 96px;
}

.introduce {
  width: 1038px;
  height: 410px;
  margin: 138px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.introduceLeft>li:nth-child(2) {
  margin: 67px 0;
}

.placeAnOrder {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

li {
  list-style: none;
}

.digitizationBox {
  width: 100%;
  padding: 1px;
  /* padding-bottom: 50px; */
  text-align: center;
  box-sizing: border-box;
}

.advantageBox {
  width: 1040px;
  margin: 82px auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.advantageBox li {
  width: 500px;
  height: 538px;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.advantageImg {
  width: 500px;
}

.digitization {
  width: 500px;
  height: 188px;
  background: linear-gradient(111deg, #F08519 0%, #EB6322 100%);
  position: relative;
}
.advantage{
  width: 440px;
  height: 240px;
  background: #fff;
  position: absolute;
  top: -82px;
  left: 30px;
}
.title{
  width: 100%;
  height: 85px;
  border-bottom: 1px dashed #877f7f;
  box-sizing: border-box;
}
.hhh{
  position: absolute;
  top: 45px;
  left: 46px;
  font-size: 24px;
}
.ct{
  width: 49px;
  height: 4px;
  background: #000;
  position: absolute;
  top: 89px;
  left: 46px;
}
.content{
  width: 347px;
  margin: 31px auto;
  text-align: left;
  font-family: 思源黑体;
  line-height: 24px;
  color: #3D3D3D;
}
.footer{
  width: 1000px;
  height: 150px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  color: white;
  flex-wrap: wrap;
}
a{
  text-decoration:none;
  color: white;
}
a:hover{
  text-decoration: underline;
}

</style>
